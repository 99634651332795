#main-container {
    &.full-width {
        padding: 10px !important;
        max-width: inherit !important;
    }
    &.disable-min-height {
        min-height: auto !important;
    }

    &.wider {
        max-width: 1000px !important;
    }

    &.narrow {
        max-width: 650px;
        margin: 0 auto;
        margin-top: 50px;
    }
}
