.swiipe-spinner-container {
    position: relative;

    .child-wrapper {
        display: inline;
        &.hide {
            visibility: hidden;
        }
    }

    &.swiipe-spinner-container--full-screen {
        .overlay,
        .spinner {
            position: fixed;
        }
    }

    .overlay {
        background-color: black;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
}
