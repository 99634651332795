@import "../../scss/partials/all.scss";
.swiipe-outer-container,
.swiipe-modal-container {
    .swiipe-standard-button {
        &.delete {
            color: white;
            border-color: $red-error;
            background-color: $red-error !important;
            text-transform: uppercase;
            &.inverted {
                color: $red-error;
                border-color: $red-error;
                background-color: white !important;
            }
        }
        &.blue {
            &.inverted {
                color: $blue-header;
                border-color: $blue-header;
                background-color: white !important;
            }
        }
        &.swiipe-btn-inv-blue {
            background-color: white !important;
            color: #215e87;
            border-color: #d1d7dc;
        }
        &.grey {
            border: 0.1em solid #797676;
            background-color: #797676 !important;
            color: white;

            &.inverted {
                border: 0.1em solid #cccccc;
                color: #797676;
                background-color: white !important;
            }
        }
        &.large {
            height: 50px;
            font-size: 16px;
            text-transform: uppercase;
        }
        &.small-btn {
            font-size: 16px;
            font-weight: 400;
            height: 38px;
        }
        &.tiny-btn {
            font-size: smaller;
            font-weight: 400;
            height: 18px;
            padding: 0 8px;
            text-transform: inherit;
        }
        &.swiipe-btn-dark {
            background-color: #1a496d !important;
        }
        &.border-radius-0 {
            border-radius: 0;
        }
    }
}
